<template>
    <dv-border-box-12 style="width: 100%;height: 100%;">
        <div style="width: 100%;height: 100%;display: flex;margin: 20px;">
            <div v-for="(item,i) in list" :key="i" style="flex: 1;display: flex;flex-direction: column;">
                <div style="color: #389edf;font-size: 14px;font-weight: bold;">{{item.title}}</div>
                <div :id="item.chartViewId" style="flex: 1;"></div>
            </div>

        </div>
    </dv-border-box-12>
</template>

<script>
import {left_op1} from "@/echarts_options";
import * as echarts from "echarts";

export default {
    name: "center_bottom",
    data: function () {
        return {
            list: [{
                title: '取水电量分析',
                options: left_op1,
                selectType: 1,
                chartViewId: 'center_charts1',
                chartView: {}
            },
                {title: '送水电量分析', options: left_op1, selectType: 1, chartViewId: 'center_charts2', chartView: {}},
            ],
            timer:{}
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    async mounted() {
        await this.initEcharts()
        this.setCharts()
        let that = this
        this.timer = setInterval(function () {
            that.setCharts()
        },3000)
    },
    methods: {
        initEcharts() {
            let that = this
            for (let i = 0; i < this.list.length; i++) {
                let item = that.list[i]
                let myChart = echarts.init(document.getElementById(item.chartViewId));
                item.chartView = myChart
                that.$set(that.list, i, item)
            }

        },
        setCharts(){

            let that = this
            for (let i = 0; i < this.list.length; i++) {
                let op = that.list[i].options
                op.grid={
                    left: '0%',
                    right: '12%',
                    bottom: '15%',
                    top: '12%',
                    containLabel: true
                }
                let list1=[]
                let list2=[]
                for (let j = 0; j < op.series[0].data.length; j++) {
                    list1.push(Math.random())
                    list2.push(Math.random())
                }
                op.series[0].data=list1
                op.series[1].data=list2
                that.list[i].chartView.setOption(op)
            }
        }
    }
}
</script>

<style scoped>

</style>