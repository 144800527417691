<template>
    <div class="rootView" :key="sizeChange">
        <dv-full-screen-container class="main-container" style="display: flex;">
            <lefft-view class="leftView" style=""/>

            <center_top
                    style="position: absolute;width: 52.7%;height: 13.5%;left: 50%;margin-left: -27.6%;z-index: 10;"/>

            <center_bottom class="center_bottom"/>

            <div class="centerView" style="z-index: -1;" id="">
            </div>
            <right_view class="rightView"/>

            <div ref="modelView" class="modelView" id="modelView"></div>
        </dv-full-screen-container>
        <Modal
                v-model="showModel"
                footer-hide
                :title="modelText"
                @on-visible-change="modalChange"
                style="z-index: 999;background-color: aqua;">
            <div v-if="showModel" style=" width: 100%;height: 350px;margin: 0;padding: 0;">
                <div id="modelCharts" class="model_charts"></div>
            </div>

        </Modal>
        z
    </div>
</template>

<script>
import lefft_view from "@/components/lefft_view.vue";
import center_top from "@/components/center_top.vue";
import center_bottom from "@/components/center_bottom.vue";
import right_view from "@/components/right_view.vue";
import * as THREE from "three";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import {OrbitControls} from "three/addons/controls/OrbitControls";
import LefftView from "@/components/lefft_view.vue";
import {left_op1} from "@/echarts_options";
import * as echarts from "echarts";

var _ = require('lodash')
export default {
    name: "mainView",
    components: {LefftView, lefft_view, center_top, center_bottom, right_view},
    data: function () {
        const width = window.innerWidth
        const height = window.innerHeight
        return {
            width: width,
            height: height,
            scene: {},
            camera: {},
            renderer: {},
            sizeChange: '0',
            granaryArr: [],
            chooseMesh: null,
            showModel: false,
            modelText: ''
        }
    },
    mounted() {
        this.init()
        let that = this
        window.onresize = function () {
            that.sizeChange = Math.random() + ''
            that.init()
        }
        addEventListener('click', function () {
            if (!that.showModel) {
                that.clickChooseMesh(event)
            }
        })
    },
    methods: {
        modalChange(e) {
            if (e) {
                this.$nextTick(() => {
                    let myChart = echarts.init(document.getElementById("modelCharts"));
                    let op = _.cloneDeep(left_op1)
                    op.legend.textStyle.color = "#000000"
                    op.xAxis.axisLine.lineStyle.color = "#000000"
                    op.yAxis.axisLine.lineStyle.color = "#000000"
                    op.grid = {
                        left: '0%',
                        right: '0%',
                        bottom: '0%',
                        top: '10%',
                        containLabel: true
                    }
                    myChart.setOption(op)
                })
            }
        },
        async init() {
            await this.initScene();
            await this.initLight()
            await this.initModel()
            await this.initCamera()
            await this.initRenderer()
            this.renderLoop()
            document.getElementById('modelView').appendChild(this.renderer.domElement);
        },
        initScene() {
            //初始化场景
            let scene = new THREE.Scene();
            // 设置雾化效果，雾的颜色和背景颜色相近，这样远处网格线和背景颜色融为一体
            scene.fog = new THREE.Fog("#011241", -100, 1000);
            // Three.js三维坐标轴 三个坐标轴颜色RGB分别对应xyz轴
            // let axesHelper = new THREE.AxesHelper(250);
            //  scene.add(axesHelper)
            this.scene = scene
        },
        initLight() {
            //光源设置
            // 平行光1
            let directionalLight = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight.position.set(300, 100, 300);
            this.scene.add(directionalLight);
            // 平行光2
            let directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight2.position.set(-300, -100, -300);
            this.scene.add(directionalLight2);
            //环境光
            let ambient = new THREE.AmbientLight(0xffffff, 1);
            this.scene.add(ambient);
        },
        initCamera() {
            const element = document.getElementById('modelView')
            //相机
            let camera = new THREE.PerspectiveCamera(30, element.offsetWidth / element.offsetHeight, 1, 3000);
            camera.position.set(458, 162, 254);//通过相机控件OrbitControls旋转相机，选择一个合适场景渲染角度
            camera.lookAt(0, 0, 0);
            this.camera = camera
        },
        initModel() {
            let that = this
            //模型
            let model = new THREE.Group();//声明一个组对象，用来添加加载成功的三维场景
            let loader = new GLTFLoader(); //创建一个GLTF加载器
            //const path = window.location.href+'model.glb'
            const path = 'model.glb'
            loader.load(path, function (gltf) {//gltf加载成功后返回一个对象
                // 递归遍历gltf.scene
                gltf.scene.traverse(function (object) {
                    if (object.type === 'Mesh') {
                        // console.log(object.name)
                        // 批量更改所有Mesh的材质
                        let names = ['Box002', '对象019', '平原', 'Cylinder128', 'Sphere115',
                            'Cylinder129', 'Sphere116', 'Cylinder130', 'Sphere117', 'Cylinder131',
                            'Sphere118', 'Cylinder134', 'Sphere121', 'Cylinder135', 'Sphere122', 'Cylinder136',
                            'Sphere123', 'Cylinder137', 'Sphere124', 'Cylinder155', 'Sphere142', 'Cylinder156',
                            'Sphere143', 'Cylinder157', 'Sphere144', 'Cylinder158', 'Sphere145', 'Cylinder161',
                            'Sphere148', 'Cylinder165', 'Sphere152', 'Cylinder166', 'Sphere153', 'Cylinder167',
                            'Sphere154', 'Cylinder168', 'Sphere155', 'Cylinder169', 'Sphere156', 'Cylinder170',
                            'Sphere157', 'Cylinder171', 'Sphere158', 'Cylinder172', 'Sphere159', 'Cylinder173',
                            'Sphere160', '马路']
                        if (names.indexOf(object.name) != -1) {
                            object.material = new THREE.MeshLambertMaterial({
                                map: object.material.map, //获取原来材质的颜色贴图属性值
                                color: '#011241', //读取原来材质的颜色
                            })
                        } else {
                            object.material = new THREE.MeshLambertMaterial({
                                map: object.material.map, //获取原来材质的颜色贴图属性值
                                color: object.material.color, //读取原来材质的颜色
                            })
                        }

                    }
                })

                // 所有粮仓模型的父对象名称：'粮仓'
                let group = gltf.scene.getObjectByName('粮仓');
                //console.log('粮仓', group);
                let list = []
                group.traverse(function (obj) {
                    if (obj.type === 'Mesh') {
                        list.push(obj);
                    }
                })
                that.granaryArr = list
                model.add(gltf.scene);
            })
            this.scene.add(model)
        },
        initRenderer() {
            const element = document.getElementById('modelView')
            //渲染器
            let renderer = new THREE.WebGLRenderer({
                antialias: true, //开启锯齿
            });
            renderer.setPixelRatio(window.devicePixelRatio);//设置设备像素比率,防止Canvas画布输出模糊。
            renderer.setSize(element.offsetWidth, element.offsetHeight); //设置渲染区域尺寸
            // 设置three.js背景颜色 和雾化颜色相配
            renderer.setClearColor("#011241", 1);
            renderer.outputEncoding = THREE.sRGBEncoding;//解决加载gltf格式模型纹理贴图和原图不一样问题
            this.renderer = renderer
            //控制器
            new OrbitControls(this.camera, this.renderer.domElement);
        },
        renderLoop() {
            this.renderer.render(this.scene, this.camera); //执行渲染操作
            requestAnimationFrame(this.renderLoop); //请求再次执行渲染函数render，渲染下一帧
        },
        clickChooseMesh(event, messageTag) {
            const element = document.getElementById('modelView')
            let that = this
            if (this.chooseMesh) {
                this.chooseMesh.material.color.set(0xffffff);// 把上次选中的mesh设置为原来的颜色
                // label.element.style.visibility = 'hidden';//隐藏标签
            }
            let Sx = event.clientX; //鼠标单击位置横坐标
            let Sy = event.clientY; //鼠标单击位置纵坐标
            //屏幕坐标转WebGL标准设备坐标
            let x = (Sx / element.offsetWidth) * 2 - 1; //WebGL标准设备横坐标
            let y = -(Sy / element.offsetHeight) * 2 + 1; //WebGL标准设备纵坐标
            //创建一个射线投射器`Raycaster`
            let raycaster = new THREE.Raycaster();
            //通过鼠标单击位置标准设备坐标和相机参数计算射线投射器`Raycaster`的射线属性.ray
            raycaster.setFromCamera(new THREE.Vector2(x, y), that.camera);
            //返回.intersectObjects()参数中射线选中的网格模型对象
            // 未选中对象返回空数组[],选中一个数组1个元素，选中两个数组两个元素
            let intersects = raycaster.intersectObjects(that.granaryArr);
            // console.log("射线器返回的对象", intersects);
            // console.log("射线投射器返回的对象 点point", intersects[0].point);
            // console.log("射线投射器的对象 几何体",intersects[0].object.geometry.vertices)
            // intersects.length大于0说明，说明选中了模型
            if (intersects.length > 0) {
                that.chooseMesh = intersects[0].object;
                that.chooseMesh.material.color.set(0x00ffff);//选中改变颜色，这样材质颜色贴图.map和color颜色会相乘
                that.chooseMesh.point = intersects[0].point;
                that.modelText = that.chooseMesh.name + "详细"
                that.showModel = true
            } else {
                that.chooseMesh = undefined;
            }
        }
    }

}
</script>

<style scoped lang="less">
.rootView {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;


  .leftView {
    z-index: 10;
    height: calc(~"100vh - 30px");
    flex: 402;
  }

  .centerView {
    display: flex;
    flex-direction: column;
    //height: calc(~"100vh - 30px");
    flex: 1011;
    justify-content: space-between;
    margin-left: 10px;
  }

  .rightView {
    z-index: 10;
    flex: 450;
    margin-left: 10px;
    height: calc(~"100vh - 30px");

  }
}

.modelView {
  //height: 64%;
  //width: 53.8%;
  //position: absolute;
  //margin: 0 auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.model_charts {
  width: 100% !important;
  height: 100% !important;

}

.main-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 15px;
  position: relative;
}

.center_bottom {
  position: absolute;

  height: 30.9% !important;
  top: calc(~"100vh - 30.9% - 15px");
  z-index: 10;
  width: 52.7% !important;
  left: 50%;
  margin-left: -27.6%;
}
</style>