<template>
    <dv-border-box-12 style="display: flex;">
        <div style="padding: 20px;display: flex;flex-direction: column;flex: 1;height: 100%;">
            <div class="item_view" v-for="(item,i) in list" :key="i" style="">
                <div style="color: #389edf;font-size: 14px;font-weight: bold;">{{ item.title }}</div>
                <div :id="item.chartViewId" style="flex: 1;"></div>
            </div>
        </div>
    </dv-border-box-12>
</template>

<script>
import {right_op1, right_op2, right_op3} from "@/echarts_options";
import * as echarts from "echarts";

export default {
    name: "right_view",
    data: function () {
        return {
            timer:{},
            list: [{
                title: '取水电量分析',
                options: right_op1,
                selectType: 1,
                chartViewId: 'right_charts1',
                chartView: {}
            },
                {title: '送水电量分析', options: right_op2, selectType: 1, chartViewId: 'right_charts2', chartView: {}},
                {title: '取水-送水占比', options: right_op3, selectType: 1, chartViewId: 'right_charts3', chartView: {}}]
        }
    },
    async mounted() {
        await this.initEcharts()
        this.setCharts()
        let that = this
        this.timer=setInterval(function () {
            that.setCharts()
        },4000)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        initEcharts() {
            let that = this
            for (let i = 0; i < this.list.length; i++) {
                let item = that.list[i]
                let myChart = echarts.init(document.getElementById(item.chartViewId));
                item.chartView = myChart
                that.$set(that.list, i, item)
            }

        },
        setCharts(){
            let that = this
            for (let i = 0; i < this.list.length; i++) {
                let op = that.list[i].options
                for (let j = 0; j < op.series.length; j++) {
                    let list =  op.series[j].data

                    op.series[j].data=list.map(res=>{
                        res.value = Math.random()
                        return res
                    })

                }
                that.list[i].chartView.setOption(op)
            }
        }
    }
}
</script>

<style scoped>

.item_view {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.item_view:first-child {
    margin-top: 0px;
}
</style>