<template>
    <div id="app">
        <!--        <dv-full-screen-container id="">

                </dv-full-screen-container>-->
        <main-view style="flex: 1;"/>

    </div>
</template>

<script>
import mainView from "@/components/mainView.vue";
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'

export default {
    name: 'App',
    components: {mainView},
    data: function () {
        return {}
    },
    mounted() {

    },
    methods: {}
}
</script>

<style lang="less">
html, body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
}

#app {
  flex: 1;
  display: flex;
}
</style>
