<template>
    <div style="width: 100%;height: 100%;display: flex;justify-content: space-between">
        <dv-border-box-12 class="item" v-for="(item,i) in list" :key="i" style="flex: 1;">
            <div style="display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
                <img :src="item.imagePath">
                <div style="display: flex;flex-direction: column;margin-left: 10px;">
                    <div style="color: #43bafe;font-size: 14px;font-weight: bold;">{{ item.title }}</div>
                    <div style="display: flex;align-items: baseline;">
                        <div style="font-size: 24px;color: #ffffff;font-weight: bold;">
                            {{ parseFloat(item.value).toFixed(2) }}
                        </div>
                        <div style="font-size: 12px;color: #43bafe;margin-left: 5px;">{{ item.unit }}</div>
                    </div>
                </div>
            </div>
        </dv-border-box-12>
    </div>

</template>

<script>
import sd from '@/assets/sl.png'
import dh from '@/assets/dh.png'
import yh from '@/assets/lh.png'

export default {
    name: "center_top",
    data: function () {
        return {
            list: [{title: "总取水量", imagePath: sd, value: Math.random(), unit: 'm³/d'},
                {title: "总电耗", imagePath: dh, value: Math.random(), unit: 'KWH/d'},
                {title: "总供水量", imagePath: sd, value: Math.random(), unit: 'm³/d'}
                , {title: "总药耗", imagePath: yh, value: Math.random(), unit: 'm³/d'}],
            timer:{}
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    mounted() {
        let that = this
       this.timer =  setInterval(function () {
            that.setData()
        },2000)
    },
    methods:{
        setData(){
            let that = this
            for (let i = 0; i < that.list.length; i++) {
                let item = that.list[i]
                item.value=Math.random()
                that.$set(that.list,i,item)
            }
        }
    }
}
</script>

<style scoped>

</style>