var dayjs = require('dayjs')

let list = []
let values = []
let values2 = []
for (let i = 0; i < dayjs().daysInMonth(); i++) {
    list.push(i+1)
    values.push(Math.random())
    values2.push(Math.random())
}


let left_op1 = {

    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: ['取水', '供水'],
        left: 10,
        textStyle: {
            color: "#ffffff"
        }
    },
    grid: {
        left: '0%',
        right: '3%',
        bottom: '0%',
        top: '15%',
        containLabel: true
    },

    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: list,
        axisLine: {
            show: true,
            lineStyle: {
                color: "#ffffff"
            }
        },
    },
    yAxis: {
        type: 'value',
        axisLine: {
            show: true,
            lineStyle: {
                color: "#ffffff"
            }
        },
        axisTick: {
            show: false
        },
        splitLine: {
            show: false
        }
    },
    series: [
        {
            name: '取水',
            type: 'line',
            smooth: true,
            lineStyle: {
                color: "#24aaff"
            },
            symbol: "none",
            data: values
        },
        {
            name: '供水',
            type: 'line',
            smooth: true,
            symbol: "none",
            lineStyle: {
                color: "#0de5bd"
            },
            data: values2
        },
    ]
};

let left_op2 = {
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: ['单日', '双日'],
        left: 10,
        textStyle: {
            color: "#ffffff"
        }
    },
    grid: {
        left: '0%',
        right: '3%',
        bottom: '0%',
        top: '20%',
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: list,
        axisLine: {
            show: true,
            lineStyle: {
                color: "#ffffff"
            }
        },
    },
    yAxis: {
        type: 'value',
        axisLine: {
            show: true,
            lineStyle: {
                color: "#ffffff"
            }
        },
        axisTick: {
            show: false
        },
        splitLine: {
            show: false
        }
    },
    series: [
        {
            data: values,
            type: 'bar',
            name: "单日",
            itemStyle: {
                normal: {
                    color: "#24aaff"
                }
            }
        },
        {
            name: '双日',
            type: 'bar',
            itemStyle: {
                normal: {
                    color: "#0de5bd"
                }
            },
            data: values2
        },
    ]
};




let left_op3 = {
    tooltip: {
        trigger: 'axis'
    },
    grid: {
        left: '0%',
        right: '3%',
        bottom: '0%',
        top: '10%',
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: [1,2,3,4,5,6,7,8,9,10,11,12],
        axisLine: {
            show: true,
            lineStyle: {
                color: "#ffffff"
            }
        },
    },
    yAxis: {
        type: 'value',
        axisLine: {
            show: true,
            lineStyle: {
                color: "#ffffff"
            }
        },
        axisTick: {
            show: false
        },
        splitLine: {
            show: false
        }
    },
    series: [
        {
            data: [Math.random(),Math.random(),Math.random(),Math.random(),Math.random(),Math.random(),Math.random(),Math.random(),Math.random(),Math.random(),Math.random(),Math.random()],
            type: 'bar',
            name: "单日",
            itemStyle: {
                normal: {
                    color: "#a999ff"
                }
            }
        },

    ]
};



let pi1_list =[{ value: Math.random(), name: "1#取水泵", label: { color: '#ff68c6' } },
    { value: Math.random(), name: "2#取水泵", label: { color: '#ff88d2' } },
    { value: Math.random(), name: "3#取水泵", label: { color: '#ff6d6d' } },
    { value: Math.random(), name: "4#取水泵", label: { color: '#fe8f35' } },
    { value: Math.random(), name: "5#取水泵", label: { color: '#ffbc6f' } },
    { value: Math.random(), name: "6#取水泵", label: { color: '#ffe84f' } },
    { value: Math.random(), name: "7#取水泵", label: { color: '#8adf60' } },
    { value: Math.random(), name: "8#取水泵", label: { color: '#0de3bd' } },
    { value: Math.random(), name: "9#取水泵", label: { color: '#43bafe' } },
    { value: Math.random(), name: "其他", label: { color: '#a998ff' } },
]

let pi2_list =[{ value: Math.random(), name: "1#取水泵", label: { color: '#ff68c6' } },
    { value: Math.random(), name: "2#送水泵", label: { color: '#ff88d2' } },
    { value: Math.random(), name: "3#送水泵", label: { color: '#ff6d6d' } },
    { value: Math.random(), name: "4#送水泵", label: { color: '#fe8f35' } },
    { value: Math.random(), name: "5#送水泵", label: { color: '#ffbc6f' } },
    { value: Math.random(), name: "6#送水泵", label: { color: '#ffe84f' } },
    { value: Math.random(), name: "7#送水泵", label: { color: '#8adf60' } },
    { value: Math.random(), name: "8#送水泵", label: { color: '#0de3bd' } },
    { value: Math.random(), name: "9#送水泵", label: { color: '#43bafe' } },
    { value: Math.random(), name: "其他", label: { color: '#a998ff' } },
]

    let right_op1= {
    tooltip: {
        trigger: "item",
            formatter: function (res) {
            return res.name + "\n" + res.value + "kwh"
        }
    },
    // color: ['#918bde', '#359cd9', '#10dcb2', '#92dd61', '#efdf49', '#ed766d'],
    series: [
        {
            name: "",
            type: "pie",
            color: ['#ff68c6', '#ff88d2', '#ff6d6d', '#fe8f35', '#ffbc6f', '#ffe84f', '#8adf60',
                '#0de3bd','#43bafe','#a998ff'],
            radius: ["40%", "70%"],
            data:pi1_list ,
            // avoidLabelOverlap: true,
            // hoverAnimation: true,
            label: {
                // normal: { show: true },
                formatter: function (res) {
                    return res.name + "\n" + res.percent + "%"

                },
                color: "#fff"
            },
            itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                borderWidth: 3,
                borderColor: '#ffffff',
                shadowColor: "rgba(0, 0, 0, 0.5)",
            },
            labelLine: {
                normal: {
                    //length: 55,   	// 指示线长度
                    show: true,
                    showAbove: false,
                    hideOverlap: false,
                    lineStyle: {
                        color: "#ffffff", // 指示线颜色
                        type: "dashed",
                        cap: "square",
                        opacity: 1
                    }
                },
            },
        },
    ],
}


let right_op2= {
    tooltip: {
        trigger: "item",
        formatter: function (res) {
            return res.name + "\n" + res.value + "kwh"
        }
    },
    // color: ['#918bde', '#359cd9', '#10dcb2', '#92dd61', '#efdf49', '#ed766d'],
    series: [
        {
            name: "",
            type: "pie",
            color: ['#ff68c6', '#ff88d2', '#ff6d6d', '#fe8f35', '#ffbc6f', '#ffe84f', '#8adf60',
            '#0de3bd','#43bafe','#a998ff'],
            radius: ["40%", "70%"],
            data:pi2_list ,
            // avoidLabelOverlap: true,
            // hoverAnimation: true,
            label: {
                // normal: { show: true },
                formatter: function (res) {
                    return res.name + "\n" + res.percent + "%"

                },
                color: "#fff"
            },
            itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                borderWidth: 2,
                borderColor: '#ffffff',
                shadowColor: "rgba(0, 0, 0, 0.5)",
            },
            labelLine: {
                normal: {
                    //length: 55,   	// 指示线长度
                    show: true,
                    showAbove: false,
                    hideOverlap: false,
                    lineStyle: {
                        color: "#ffffff", // 指示线颜色
                        type: "dashed",
                        cap: "square",
                        opacity: 1
                    }
                },
            },
        },
    ],
}



let right_op3= {
    tooltip: {
        trigger: "item",
        formatter: function (res) {
            return res.name + "\n" + res.value + "kwh"
        }
    },
    // color: ['#918bde', '#359cd9', '#10dcb2', '#92dd61', '#efdf49', '#ed766d'],
    series: [
        {
            name: "",
            type: "pie",
            color: ['#8adf60', '#0de3bd', '#42bbfd'],
            radius: ["40%", "75%"],
            data:[{ value: Math.random(), name: "其他", label: { color: '#8adf60' } },
                { value: Math.random(), name: "取水", label: { color: '#0de3bd' } },
                { value: Math.random(), name: "送水", label: { color: '#42bbfd' } },
            ] ,
            // avoidLabelOverlap: true,
            // hoverAnimation: true,
            label: {
                // normal: { show: true },
                formatter: function (res) {
                    return res.name + "\n" + res.percent + "%"

                },
                color: "#fff"
            },
            itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                borderWidth: 2,
                borderColor: '#ffffff',
                shadowColor: "rgba(0, 0, 0, 0.5)",
            },
            labelLine: {
                normal: {
                    //length: 55,   	// 指示线长度
                    show: true,
                    showAbove: false,
                    hideOverlap: false,
                    lineStyle: {
                        color: "#ffffff", // 指示线颜色
                        type: "dashed",
                        cap: "square",
                        opacity: 1
                    }
                },
            },
        },
    ],
}
export {left_op1, left_op2,left_op3,right_op1,right_op2,right_op3}