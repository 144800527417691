<template>
    <dv-border-box-12 class="leftRoot">
        <div style="padding: 20px;display: flex;flex-direction: column;height: 100%;">
            <div class="item_view" v-for="(item,i) in list" :key="i" style="">
                <div class="heard_view">
                    <div class="heard_content">
                        <div class="title">{{ item.title }}</div>
                        <div class="select_view">
                            <div @click="selectClick(item)"
                                 :class="{'select_text':true,'select_text_selected':item.selectType==1}">日
                            </div>
                            <div @click="selectClick(item)"
                                 :class="{'select_text':true,'select_text_selected':item.selectType==2}">月
                            </div>
                        </div>
                    </div>
                    <Divider class="divider"/>
                </div>
                <div :id="item.chartViewId" style="flex: 1;"></div>

            </div>
        </div>
    </dv-border-box-12>

</template>

<script>
import * as echarts from 'echarts';
import {left_op1, left_op2, left_op3} from '@/echarts_options'

var _ = require('lodash')
export default {
    name: "leftView",
    data: function () {
        return {
            list: [{
                title: '取供水量统计',
                options: _.cloneDeep(left_op1),
                selectType: 1,
                chartViewId: 'charts1',
                chartView: {}
            },
                {
                    title: '千吨水电耗率',
                    options: _.cloneDeep(left_op2),
                    selectType: 1,
                    chartViewId: 'charts2',
                    chartView: {}
                },
                {
                    title: '千吨水药耗率',
                    options: _.cloneDeep(left_op3),
                    selectType: 1,
                    chartViewId: 'charts3',
                    chartView: {}
                }]
        }
    },
    async mounted() {
        await this.initEcharts()
        this.setCharts()
        let that = this
        this.timer = setInterval(function () {
            that.setCharts()
        }, 3500)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        selectClick(item) {
            const index = this.list.indexOf(item)
            const type = item.selectType == 1 ? 2 : 1
            item.selectType = type
            this.$set(this.list, index, item)
        },
        initEcharts() {
            let that = this
            for (let i = 0; i < this.list.length; i++) {
                let item = that.list[i]
                let myChart = echarts.init(document.getElementById(item.chartViewId));
                item.chartView = myChart
                that.$set(that.list, i, item)
            }

        },
        setCharts() {
            let that = this
            for (let i = 0; i < this.list.length; i++) {
                let op = that.list[i].options
                for (let j = 0; j < op.series.length; j++) {
                    let list = []
                    for (let k = 0; k < op.series[j].data.length; k++) {
                        list.push(Math.random())
                    }
                    op.series[j].data = list
                }
                that.list[i].chartView.setOption(op)
            }
        }
    }

}
</script>

<style scoped lang="less">
.leftRoot {
  color: white;
}

.item_view {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.item_view:first-child {
  margin-top: 0px;
}

.heard_view {

  display: flex;
  flex-direction: column;

  .heard_content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #389edf;
      font-size: 14px;
      font-weight: bold;
    }

    .select_view {
      display: flex;

      .select_text {
        width: 40px;
        height: 30px;
        color: white;
        background-color: #073473;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px 4px 0px 0px;
      }

      .select_text:last-child {
        margin-left: 5px;
      }

      .select_text_selected {
        background-color: #087dd7;
      }
    }
  }

  .divider {
    margin: 0px;
    background-color: #354b9a;
  }

}
</style>